let previousWidth = 0;

const loadBackground = (): void => {
    const back: HTMLElement = document.querySelector('.background')!;
    const screenWidth = Math.min(window.innerWidth, 3000);
    const defaultImage = 'https://images.unsplash.com/photo-1475869422403-ae2c14a6e5f7';

    if (previousWidth >= screenWidth) return;

    const imageWidth = screenWidth > 640 ? screenWidth : screenWidth * 0.5;
    const url = `${back.dataset.bg || defaultImage}?q=80&w=${imageWidth}`;
    const image = new Image();
    previousWidth = screenWidth;
    image.onload = () => {
        back.style.backgroundImage = `url(${url})`;
        back.style.opacity = '1';
    };
    image.src = url;
};

const debounce = (func: () => void, wait: number): (() => void) => {
    let timeout: number | null = null;
    return () => {
        if (timeout !== null) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(func, wait);
    };
};

loadBackground();

window.addEventListener('resize', debounce(() => {
    loadBackground();
}, 100));